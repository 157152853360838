.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.LeftButton {
  position: relative;
  top: 350px;
  right: -14px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  padding: 5px;
}

.RightButton {
  position: relative;
  top: -280px;
  left: 1408px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  padding: 5px;
}

.bannerContainer {
  display: flex;
  justify-content: center;
  height: 600px;
  margin: 0 0 50px 0;
  padding-bottom: 6.6vmax;
}

.BannerInner {
  width: 95%;
}

@font-face {
  font-family: roc-grotesk;
  /* src: url(../public/Fontspring-DEMO-rocgroteskwide-light.otf); */
  src: url(../public/FontsFree-Net-RocGrotesk.ttf);
}

.roc-grotesk {
  font-family: roc-grotesk;
}

#about-image {
  flex: 0 0 auto !important;
}
#about-content {
  flex: 0 0 auto !important;
}
