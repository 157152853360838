/*          Responsive styles
================================================== */

@media only screen and (min-width: 1678px) and (max-width: 1923px) {
}

@media only screen and (min-width: 1390px) and (max-width: 1677px) {
}
/*                     Tablet Landscape
=======================================================================================*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /*--------------------------------------
   Header And SLider
----------------------------------------*/
}

/* For Header */
@media (max-width: 991px) {
}

/*                      Tablet Portrait
=======================================================================================*/

@media (min-width: 768px) and (max-width: 991px) {
}
/*                          Phone
=======================================================================================*/

@media only screen and (max-width: 767px) {
  .details-img-placeholder {
    width: 100%;
    height: 500px;
  }
  .FashionImage {
    object-fit: contain;
    min-height: 541px;
    width: 100%;
  }

  .mobile-container-img {
    min-height: 541px;
  }
  .hero-section-banner-img {
    height: 600px;
    object-fit: contain;
  }

  .categories-img-container-placeholder {
    width: 100%;
    height: 250px;
  }

  .DetailsMainSection {
    padding: 0;
  }
  .DetailsTitleMain {
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 2.3vmax;
    padding-top: 2.3vmax;
  }
  .TitleMain {
    text-align: center;
    white-space: pre-wrap;
    font-size: 2.5rem;
    font-weight: 400;
    padding-top: 3.3vmax;
    padding-bottom: 3.3vmax;
  }

  .categories-img-container {
    /* width: auto; */
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
/* Mobile Vertical Layout: 488px 
===============================================================================*/
@media only screen and (min-width: 8px) and (max-width: 380px) {
}
