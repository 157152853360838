@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulse {
  50% {
    background: white;
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}


.loading {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 12px;
  border: 0.25rem solid  rgb(152, 181, 255);
  border-top-color:  rgb(16, 60, 170);
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  z-index: 100;
  margin-bottom: -5.3rem;
  
} 
.logoloader {
  position: absolute;
  top: 50%;
  left: 50%;
  
  transform: translate(-50%, -50%); /* Center the logo within the loader */

}


 
