h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "roc-grotesk", sans-serif;
}

.border-bt {
  border-bottom: 1px solid #000;
}
.roc-grotesk {
  font-family: "roc-grotesk", sans-serif;
}
.nav-bar {
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.navHeader {
  font-family: "roc-grotesk";
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 16px;
  align-items: center;
}

/* YourCustomDropdown.css */

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 10px;
  /* background-color: #eee;
  border: 1px solid #ddd; */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-dropdown.open .dropdown-menu {
  display: block;
}

/* Styling for the dropdown items */
.custom-dropdown .dropdown-menu a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.custom-dropdown .dropdown-menu a:hover {
  background-color: #ddd;
  color: #333;
}

.fs18px {
  font-size: 16px;
}

.DetailsTitleMain {
  font-size: 5rem;
  font-weight: 400px;
  padding-bottom: 3.3vmax;
}
.FashionImage {
  width: auto;
  height: auto;
}
.GalleryMain {
  max-width: 100%;
  padding: 4vw;
}
.DetailsMainSection {
  padding: 0 34px;
}
.detailsDescription {
  font-size: 1.7rem;
  font-weight: 400px;
  margin: 0 0 32px 0;
}
.detailsDummy {
  font-size: 1rem;
}

.TitleMain {
  text-align: center;
  white-space: pre-wrap;
  font-size: 5.5rem;
  font-weight: 400px;

  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
}
.navbar-toggler {
  border: none;
}

.sectionAboutUs {
  /* padding-right: 4vw; */
  /* padding-left: 4vw; */
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
}

.hero-section {
  /* height: 600px; */
  padding: 4vw;
}

.categories-img-container {
  /* width: 556px; */
  /* width: 556px; */
  height: 557px;
  object-fit: cover;
}
.categories-img-container-placeholder {
  width: 100%;
  height: 557px;
  object-fit: cover;
}

.hero-area-container {
  height: 600px;
}

.aboutUs-img-placeholder {
  width: 100%;
  height: 650px;
}
.details-img-placeholder {
  width: 100%;
  height: 650px;
}

.hero-section-banner-img {
  height: 600px;
  object-fit: contain;
}

.equal-height-image {
  height: 100%; /* Set a percentage height */
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.75vw;
  grid-row-gap: 1.75vw;
  width: auto;
}

.grid-item {
  /* Add any specific styling for each grid item if necessary */
}
